<template>
  <div>
    <Dialog 
      :header="store.addressModal.title" 
      v-model:visible="store.addressModal.show" 
      :style="{width: '480px'}" 
      :modal="true"
    >
      <div>
        <div class="mb-3">
          <label for="address" class="form-label-font mb-1">Address</label>
          <input 
            v-model="address.address" 
            :class="['form-control', { 'is-invalid': v$.address.address.$errors.length }]" 
            type="text" 
            name="address" 
            id="address"
          />
        </div>
        <div class="mb-3">
          <label for="city" class="form-label-font mb-1">City</label>
          <input 
            v-model="address.city" 
            :class="['form-control', { 'is-invalid': v$.address.city.$errors.length }]" 
            type="text" 
            name="city" 
            id="city"
          />
        </div>
        <div class="mb-3">
          <label for="state" class="form-label-font mb-1">State</label>
          <select v-model="address.state" name="state" id="state" :class="['form-select', { 'is-invalid': v$.address.state.$errors.length }]" >
            <option value="">Select State</option>
            <option v-for="(state, index) in mainStore.states" :key="index" :value="index">
              {{ state }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="zip" class="form-label-font mb-1">ZIP Code</label>
          <input 
            v-model="address.zip" 
            :class="['form-control', { 'is-invalid': v$.address.zip.$errors.length }]" 
            type="text" 
            name="zip" 
            id="zip"
          />
        </div>
        <div>
          <button type="button" class="btn btn-primary w-100" @click="this[store.addressModal['callMethod']]()">Save</button>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog/sfc'
import { mainStore as main } from "@/stores/mainStore"
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore"
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  data() {
    return {
      address: {
        address: '',
        city: '',
        state: '',
        zip: '',
        type: ''
      }
    }
  },
  setup() {
    const store = finalizeQuoteStore()
    const mainStore = main()
    return { store, mainStore, v$: useVuelidate() }
  },
  validations() {
    return {
      address: {
        address: { required },
        city: { required },
        state: { required },
        zip: { required }
      }
    }
  },
  components: {
    Dialog
  },
  watch: {
    'store.addressModal.show': function () {
      if (this.store.addressModal.show && this.store.addressModal.callMethod == 'update') {
        const selectedAddress = this.store.submissionData.address.filter(address => {
          return address.type == this.store.addressModal.type
        })
        this.address = selectedAddress[0]
      }
    },
  },
  methods: {
    async update() {
      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.updateAddress(this.address)
      this.store.addressModal.show = false
    }
  }
}
</script>