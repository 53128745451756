<template>
	<div class="progress-bar-body fixed-top">
		<div class="container ">
			<div class="row justify-content-center">
				<div class="col-sm-12 col-md-8 col-lg-6 col-xl-4">
					<!-- text data -->
					<div class="mb-2">
						<span class="fontbody5">⚠️ There is 1 required field remaining. </span>
						<span class="fontbody5"> 75% Complete</span>
					</div>
					<!-- Progress bar -->
					<div class="progress">
						<div class="progress-bar w-75" role="progressbar"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style>
/* Top 68 pixel for the top navbar */
.progress-bar-body {
	top:68px!important;
	background: white;
	padding: 12px;
	border-bottom: 1px solid #E6E1E9;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	margin-bottom: 24px;
}

.progress-bar-body .progress {
	height: 13px;
	border: solid 1px var(--colorPrimaryMain);
	background-color: #FFF;
}

.progress-bar-body .progress .progress-bar {
	color: var(--colorPrimaryMain);
	background-color: var(--colorPrimaryMain);
}
</style>