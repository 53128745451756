<template>
  <div>
    <div class="pt-5">
      <div class="loader-file mt-5">
        <img src="@/assets/img/loader_files.gif" alt="Loading" />
      </div>
      <div class="mt-3 text-center">
        <p>Generating PricePreview...</p>
      </div>
    </div>
  </div>
</template>
