<template>
  <div>
    <TopNavbar :nav-link="store.mainNavigationURL"/>

    <div class="container">
      <div class="row">
        <div class="col qq-container">

          <div class="mt-5">
            <h1 class="color-primary">PricePreview</h1>
            <p>Get an accurate indication in a flash.</p>
          </div>

          <div class="mt-5">
            <h3 class="color-neutral"> What you'll need:</h3>
            <ul class="checklist">
              <li class="mt-4">DOT number or Garaging Location</li>
              <li class="mt-4">VIN or Year, Make, Model, and Value</li>
              <li class="mt-4">Driver's Age and Years of Experience</li>
            </ul>
          </div>
          <div class="mt-5 pt-2">
            <button @click="$router.push('/dot')" class="btn btn-primary w-100"> Let's Go</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {quickQuoteStore} from "@/stores/quickQuoteStore";
import {useRoute} from "vue-router";
import TopNavbar from "@/components/TopNavbar.vue";

export default {
  components: {TopNavbar},

  setup() {
    const route = useRoute();
    let userId = parseInt(route.params.userId)
    const store = quickQuoteStore();
    store.$patch({
      userId: userId
    })
    return {store};
  },
  beforeMount() {
    this.store.resetData()
  }
}
</script>
