import {defineStore} from 'pinia'
import axios from 'axios'
import cwApiJson from '@/assets/json/cwapi'
import moment from 'moment';
import {useAuthStore} from "@/stores/authStore";

const API_GATEWAY_URL = process.env.VUE_APP_API_GATEWAY_URL;
const PLATFORM_URL = process.env.VUE_APP_PLATFORM_URL;

export const quickQuoteStore = defineStore('quickQuoteStore', {
    state: () => ({
        submissionData: {},
        cwjson: structuredClone(cwApiJson),
        quoteResponse: {},
        userId: 0,
        platformURL: PLATFORM_URL,
        mainNavigationURL: PLATFORM_URL + '/transportation'
    }),
    persist: true,
    actions: {
        //GET DOT DATA AND POPULATE SUBMISSION
        async getDotData(dot) {
            await
                axios.get(`${API_GATEWAY_URL}/v1/dot/` + dot)
                    .then((res) => {
                        if (res.data) {
                            this.submissionData.manualDot = false
                            this.submissionData.dotNotFound = false
                            this.submissionData.dot = res.data.dotNumber
                            this.submissionData.legalName = res.data.legalName
                            this.submissionData.dbaName = res.data.dbaName
                            this.submissionData.phyAddressStreet = res.data.physicalAddressStreet
                            this.submissionData.phyAddressCity = res.data.physicalAddressCity
                            this.submissionData.phyAddressState = res.data.physicalAddressState
                            this.submissionData.phyAddressZip = res.data.physicalAddressZipCode.slice(0, 5)
                            this.submissionData.mailAddressStreet = res.data.mailAddressStreet
                            this.submissionData.mailAddressCity = res.data.mailAddressCity
                            this.submissionData.mailAddressState = res.data.mailAddressState
                            this.submissionData.mailAddressZip = res.data.mailAddressZipCode
                            this.submissionData.totalPowerUnits = res.data.totalPowerUnits
                            this.submissionData.country = res.data.country
                        } else {
                            this.submissionData = {}
                            this.submissionData.manualDot = true
                            this.submissionData.dotNotFound = true
                        }
                    })
                    .catch((e) => {
                        // Handle data not found or error the same as not finding vehicle data
                        // and needing manual entry
                        if (e.response.status === 404) {
                            this.resetDotData()
                            this.submissionData.manualDot = true
                            this.submissionData.dotNotFound = true
                        }
                        console.log(e)
                    })
        },
        //GET VIN DATA AND POPULATE SUBMISSION
        async getVinData(vin) {
            await
                axios.get(`${API_GATEWAY_URL}/v1/vehicle/value/vin/` + vin)
                    .then((res) => {
                        if (res.data) {
                            this.submissionData.manualVdata = false
                            this.submissionData.vinNotFound = false
                            this.submissionData.configurationId = res.data.configurationId
                            this.submissionData.equipmentYear = res.data.modelYear
                            this.submissionData.equipmentMake = res.data.manufacturerName
                            this.submissionData.equipmentModel = res.data.modelName
                            this.submissionData.sizeClassName = res.data.sizeClassName
                            this.submissionData.equipmentValue = res.data.unadjustedRetail
                        }
                    })
                    .catch((e) => {
                        // Handle data not found or error the same as not finding vehicle data
                        // and needing manual entry
                        if (e.response.status === 404) {
                            this.resetVINData()
                            this.submissionData.vinNotFound = true
                            this.submissionData.manualVdata = true
                        }
                        console.log(e)
                    })
        },
        // Search for vehicle based on year, model, manufacturer
        async searchForVehicleData(year, model, manufacturer) {
            await
                axios.get(`${API_GATEWAY_URL}/v1/vehicle/search?year=${year}&model=${model}&manufacturer=${manufacturer}`)
                    .then((res) => {
                        if (res.data) {
                            // this.submissionData.manualVdata = true
                            // this.submissionData.vinNotFound = true
                            this.submissionData.equipmentFound = true
                            this.submissionData.equipmentValue = res.data.unadjustedRetail
                        } else {
                            this.submissionData.equipmentFound = false
                            this.resetVINData()
                        }
                    })
                    .catch((e) => console.log(e))
        },
        //GET QUOTE FROM CW API
        async getQuote() {
            this.authStore = useAuthStore();
            // Clean quote Response
            this.quoteResponse = {};
            await
                axios.post(`${API_GATEWAY_URL}/v1/qq`,
                    this.cwjson,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + this.authStore.authToken
                        }
                    })
                    .then((res) => {
                        if (res.data && (res.data.status === "Quoted" || res.data.status === "Declined")) {
                            this.submissionData.quoteRequested = true
                            this.quoteResponse = res.data;
                            if (this.quoteResponse && this.quoteResponse.coverages) {
                                let totalQuote = this.calculateTotalQuote(this.quoteResponse.coverages)
                                this.quoteResponse.totalQuote = totalQuote
                            }
                        } else {
                            console.log('Not found')
                        }
                    })
                    .catch((e) => {
                        this.quoteResponse.status = "Errored"
                        console.log(e)
                    })
        },

        // Reset all Equipment vin data
        async resetVINData() {
            this.submissionData.vinNotFound = false
            this.submissionData.manualVdata = false
            this.submissionData.configurationId = ''
            this.submissionData.equipmentVin = ''
            this.submissionData.equipmentYear = ''
            this.submissionData.equipmentMake = ''
            this.submissionData.equipmentModel = ''
            this.submissionData.equipmentValue = ''
        },

        async resetDotData() {
            this.submissionData.dot = ''
            this.submissionData.legalName = ''
            this.submissionData.dbaName = ''
            this.submissionData.phyAddressStreet = ''
            this.submissionData.phyAddressCity = ''
            this.submissionData.phyAddressState = ''
            this.submissionData.phyAddressZip = null
            this.submissionData.mailAddressStreet = ''
            this.submissionData.mailAddressCity = ''
            this.submissionData.mailAddressState = ''
            this.submissionData.mailAddressZip = null
            this.submissionData.totalPowerUnits = null
            this.submissionData.country = ''
        },

        // Reset all Submission data
        async resetData() {
            this.submissionData = {}
            this.quoteResponse = {}
            this.cwjson = structuredClone(cwApiJson)
        },

        // Builds the submission
        async buildSubmission() {
            this.cwjson.coverage.effectiveDate = moment(new Date()).format("MM/DD/YYYY")
            this.cwjson.insuredInformation.dotNumber = this.submissionData.dot
            this.cwjson.insuredInformation.legalName = this.submissionData.legalName
            this.cwjson.insuredInformation.dbaName = this.submissionData.dbaName
            this.cwjson.limits.valueOfTrucks = this.submissionData.equipmentValue
            this.cwjson.operations.opsLocal = this.submissionData.radius_local
            this.cwjson.operations.opsIntermediate = this.submissionData.radius_intermediate
            this.cwjson.operations.opsLongHaul = this.submissionData.radius_longhaul
            this.cwjson.radius.radius0_50 = this.submissionData.radius0_50
            this.cwjson.radius.radius51_200 = this.submissionData.radius51_200
            this.cwjson.radius.radius201_500 = this.submissionData.radius201_500
            this.cwjson.radius.radius501 = this.submissionData.radius501
            this.cwjson.mailingAddress.mailingStreet = this.submissionData.mailAddressStreet
            this.cwjson.mailingAddress.mailingCity = this.submissionData.mailAddressCity
            this.cwjson.mailingAddress.mailingState = this.submissionData.mailAddressState
            this.cwjson.mailingAddress.mailingZip = this.submissionData.mailAddressZip
            this.cwjson.mailingAddress.mailingCountry = this.submissionData.mailAddressCountry
            this.cwjson.shippingAddress.shippingStreet = this.submissionData.mailAddressStreet
            this.cwjson.shippingAddress.shippingCity = this.submissionData.mailAddressCity
            this.cwjson.shippingAddress.shippingState = this.submissionData.mailAddressState
            this.cwjson.shippingAddress.shippingZip = this.submissionData.mailAddressZip
            this.cwjson.shippingAddress.shippingCountry = this.submissionData.mailAddressCountry
            // Additional terminal is not necessary - garaging address creates terminal
            // this.cwjson.terminals.push({
            //     terminalStreet: this.submissionData.mailAddressStreet,
            //     terminalCity: this.submissionData.mailAddressCity,
            //     terminalState: this.submissionData.mailAddressState,
            //     terminalZip: this.submissionData.mailAddressZip,
            //     terminalCounty: this.submissionData.mailingCounty,
            // })
            this.cwjson.vehicles.push({
                vin: await this.determineVIN(this.submissionData.equipmentVin),
                year: this.submissionData.equipmentYear,
                make: this.submissionData.equipmentMake,
                model: this.submissionData.equipmentModel,
                classKey: this.submissionData.sizeClassName ?? '8', // Defaults class to 8, which is a bad assumption
                value: Math.floor(this.submissionData.equipmentValue),
                bodyTypeKey: await this.determineBodyType(this.submissionData.sizeClassName),
                deductibleType: "FollowsPolicy",
                deductible: "2500"
            })
            this.cwjson.drivers.push({
                firstName: "Driver",
                lastName: "DriverLast",
                licenseState: "CA",
                licenseNumber: "d120785866330",
                dateOfBirth: this.submissionData.policyHolderDOB,
                dateOfHire: "11/15/2022",
                yearsExperience: this.submissionData.yearsOnEquipment,
                eligibility: "Covered",
            })
            this.cwjson.userId = this.userId
        },
        async determineBodyType(sizeClassName) {
            // Defaults to tractor - but this is a guess and a bad one
            // TODO: Shift this logic into the equipment-info service
            const bodyTypes = {
                '1': 'pick_up',
                '2a': 'pick_up',
                '2b': 'pick_up',
                '3': 'pick_up',
                '4': 'pick_up',
                '5': 'pick_up',
                '6': 'box_truck',
                '7': 'box_truck',
                '8': 'tractor',
            };
            return (bodyTypes[sizeClassName] || 'tractor');
        },
        async determineVIN(vin) {
            return vin === '' ? '12345678901234567' : vin
        },
        calculateTotalQuote(coverages) {
            let totalQuote = 0;
            Object
                .values(coverages)
                .filter(coverage => coverage !== null)
                .forEach(coverage => {
                        totalQuote += coverage.totalCost;
                    }
                );
            return totalQuote;
        }
    }
})
