import {createApp, markRaw} from 'vue'
import {createPinia} from 'pinia'
import piniaPluginPersisted from 'pinia-plugin-persistedstate'
import PrimeVue from 'primevue/config'
import VueNumberFormat from 'vue-number-format'
import App from './App.vue'
import router from './router'
import LogRocket from 'logrocket';

// CSS
import "bootstrap/dist/css/bootstrap.min.css"
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '@/assets/css/cwcustom.css'

const app = createApp(App)
LogRocket.init('hnxjfk/quick-quote');
const pinia = createPinia()
app.use(pinia)
pinia.use(piniaPluginPersisted)
pinia.use(({store}) => {
    store.router = markRaw(router)
})
app.use(router)
app.use(VueNumberFormat, {prefix: '$', decimal: '.', thousand: ','})
app.use(PrimeVue)
app.mount('#app')
