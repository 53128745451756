<template>
  <div>
    <Dialog 
      :header="store.truckModal.title" 
      v-model:visible="store.truckModal.show" 
      :style="{width: '480px'}" 
      :modal="true"
    >
      <TabView>
        <TabPanel header="Truck">
          <div>
            <div class="mb-3">
              <label for="vin" class="form-label-font mb-1">VIN</label>
              <input 
                v-model="truck.vin" 
                :class="['form-control', { 'is-invalid': v$.truck.vin.$errors.length }]" 
                type="text" 
                name="vin" 
                id="vin"
              />
            </div>
            <div class="mb-3">
              <label for="year" class="form-label-font mb-1">Year</label>
              <input 
                v-model="truck.year" 
                :class="['form-control', { 'is-invalid': v$.truck.year.$errors.length }]" 
                type="text" 
                name="year" 
                id="year"
              />
            </div>
            <div class="mb-3">
              <label for="make" class="form-label-font mb-1">Make</label>
              <input 
                v-model="truck.make" 
                :class="['form-control', { 'is-invalid': v$.truck.make.$errors.length }]" 
                type="text" 
                name="make" 
                id="make"
              />
            </div>
            <div class="mb-3">
              <label for="" class="form-label-font mb-1">Model</label>
              <input 
                v-model="truck.model" 
                :class="['form-control', { 'is-invalid': v$.truck.model.$errors.length }]" 
                type="text" 
                name="model" 
                id="model"
              />
            </div>
            <div class="mb-3">
              <label for="value" class="form-label-font mb-1">Value</label>
              <input 
                v-model="truck.value" 
                :class="['form-control', { 'is-invalid': v$.truck.value.$errors.length }]" 
                type="text" 
                name="value" 
                id="value"
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Loss payee">
          <div>
            <div class="mb-3">
              <label for="loss_payee_name" class="form-label-font mb-1">Name</label>
              <input 
                class="form-control"
                type="text" 
                name="loss_payee_name" 
                id="loss_payee_name"
              />
            </div>
            <div class="mb-3">
              <label for="loss_payee_address" class="form-label-font mb-1">Address</label>
              <input 
                class="form-control"
                type="text" 
                name="loss_payee_address" 
                id="loss_payee_address"
              />
            </div>
            <div class="mb-3">
              <label for="loss_payee_city" class="form-label-font mb-1">City</label>
              <input 
                class="form-control"
                type="text" 
                name="loss_payee_city" 
                id="loss_payee_city"
              />
            </div>
            <div class="mb-3">
              <label for="loss_payee_state" class="form-label-font mb-1">State</label>
              <select class="form-control" name="loss_payee_state" id="loss_payee_state">
                <option></option>
              </select>
            </div>
            <div class="mb-3">
              <label for="loss_payee_zip_code" class="form-label-font mb-1">ZIP Code</label>
              <input 
                class="form-control"
                type="text" 
                name="loss_payee_zip_code" 
                id="loss_payee_zip_code"
              />
            </div>
          </div>
        </TabPanel>
      </TabView>
      <div>
        <button type="button" class="btn btn-primary w-100" @click="this[store.truckModal['callMethod']]()">Save</button>
      </div>
    </Dialog>
  </div>
</template>
  
<script>
import Dialog from 'primevue/dialog/sfc'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  data() {
    return {
      truck: {
        vin: null,
        year: null,
        make: null,
        model: null,
        value: null
      }
    }
  },
  setup() {
    const store = finalizeQuoteStore()
    return { store, v$: useVuelidate() }
  },
  validations() {
    return {
      truck: {
        vin: { required },
        year: { required },
        make: { required },
        model: { required },
        value: { required }
      }
    }
  },
  components: {
    Dialog,
    TabView,
    TabPanel
  },
  watch: {
    'store.truckModal.show': function () {
      if (this.store.truckModal.show && this.store.truckModal.callMethod == 'update') {
        this.truck = this.store.submissionData.vehicles[this.store.selectedVehicleToUpdate]
      } else {
        this.resetForm()
      }
    },
  },
  methods: {
    resetForm() {
      // Clear form fields
      this.truck = {
        vin: null,
        year: null,
        make: null,
        model: null,
        value: null
      }
      // Reset validation
      this.v$.$reset()
    },
    async update() {
      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.updateTruck(this.truck)
      this.store.truckModal.show = false

      this.resetForm()
    },
    async save() {
      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.saveTruck(this.truck)
      this.store.truckModal.show = false

      this.resetForm()
    }
  }
}
</script>