<template>
  <div class="accordion-conponent separator">
    <!-- Tittle -->
    <h3>Cargo</h3>
    <p class="fontbody3">Please add as many commodities as applies. Total percentage should equal 100%.</p>

    <div class="row">

      <!-- Cargo Options -->
      <div class="col-md-6 col-xl-4  mt-4" v-for="(cargoSelected, index) in store.selectedCommodities" :key="index">
        <div class="card cargo-card">
          
          <!-- Card body -->
          <div class="card-body">
            <h5 class="card-subtitle">{{ cargoSelected.label }}</h5>
            <div class="mt-4 cargo-input">
              <select v-model="cargoSelected.percentage" class="form-control">
                <option v-for="n in 100" v-bind:key="n">{{ n }}</option>
              </select>
              <div class="percentage-icon">%</div>
            </div>

            <!-- Overlay-menu-->
            <div @click="this.$refs.cargoMenu[0].toggle($event)" class="edit-menu clickable">
              <i class="pi pi-ellipsis-v"></i>
              <Menu ref="cargoMenu" class="overlay-menu" :popup="true">
                <template #start>
                  <ul>
                    <li @click="editCargo(index)">Edit</li>
                    <li @click="removeCargo(index)">Remove</li>
                  </ul>
                </template>
              </Menu>
            </div>

          </div>
        </div>
      </div>

      <!-- ADD COMODITY CARD -->
      <div class="col-lg-6 col-xl-4  mt-4">
        <div class="card cargo-card clickable" @click="addCargo()">
          <div class="card-body">
            <div class="text-center mt-1 color-primary">
              <i class="pi pi-plus-circle" style="font-size: 2rem"></i>
              <h5 class="mt-2">Add commodity</h5>
              <p class="fontbody5">What is the policyholder hauling?</p>
            </div>    
          </div>
        </div>
      </div>

      <ModalAddCArgo />

    </div> 
  </div>
</template>

<script>
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore"
import ModalAddCArgo from '@/components/FinalizeQuote/Operations/ModalAddCargo'
import Menu from 'primevue/menu';


export default {
  components: {
    ModalAddCArgo,
    Menu
  },
  setup() {
    const store = finalizeQuoteStore()
    return { store }
  },
  methods: {
    // Adds another cargo option
    addCargo() {
      this.store.cargoModal.show = true
    },
    // Edit selected cargo Option Commodity
    editCargo(index) {
      this.store.cargoModal.title = 'Edit commodity'
      this.store.cargoModal.callMethod = 'update'
      this.store.selectedCommodityToUpdate = index
      this.store.cargoModal.show = true
    },
    // Remove Selected Cargo Commodity
    removeCargo(index) {
      this.store.removeCommodity(index)
    }
  }
}
</script>

<style>
.card.cargo-card{
  height: 156px;
}
.cargo-card .cargo-input{
  text-align: center;
  margin:auto;
}
.cargo-card .cargo-input select,
.cargo-card.cargo-input select:focus{
  font-size: 22px;
  max-width: 65px;
  display: inline;
  text-align: center;
}
.cargo-card .cargo-input .percentage-icon{
  display: inline;
  font-size: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  margin-left: 12px;
}
</style>