<template>
  <div>
    <Dialog 
      :header="store.cargoModal.title" 
      v-model:visible="store.cargoModal.show" 
      :style="{width: '480px'}" 
      :modal="true"
    >
      <div>
        <div class="mb-3">
          <label for="commodity" class="form-label-font mb-1">Commodity</label> <br>
          <span class="p-input-icon-right">
            <AutoComplete
              v-model="selectedCommodity"
              optionLabel="label"
              :suggestions="filteredCommodities"
              @complete="getCommodities($event)"
            />
            <i class="pi pi-search" />
          </span>
        </div>
        <div>
          <button type="button" class="btn btn-primary w-100" @click="this[store.cargoModal['callMethod']]()">Save</button>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog/sfc'
import AutoComplete from 'primevue/autocomplete'
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore"
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  data() {
    return {
      selectedCommodity: null,
      filteredCommodities: []
    }
  },
  setup() {
    const store = finalizeQuoteStore()
    return { store, v$: useVuelidate() }
  },
  validations() {
    return {
      selectedCommodity: { required }
    }
  },
  components: {
    Dialog,
    AutoComplete
  },
  watch: {
    'store.cargoModal.show': function () {
      if (this.store.cargoModal.show && this.store.cargoModal.callMethod == 'update') {
        this.selectedCommodity = this.store.selectedCommodities[this.store.selectedCommodityToUpdate]
      }
    },
  },
  methods: {
    getCommodities({ query }) {
      if (!query.trim()) {
        this.filteredCommodities = [...this.store.commodityOptions]
        return
      }
      this.filteredCommodities = this.store.commodityOptions.filter((commodity) => commodity.label.toLowerCase().includes(query.toLowerCase()))
    },
    async update() {
      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.updateCommodity(this.selectedCommodity)
      this.store.cargoModal.show = false

      // Clear form fields
      this.selectedCommodity = null 
    },
    async save() {
      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.saveCommodity(this.selectedCommodity)
      this.store.cargoModal.show = false

      // Clear form fields
      this.selectedCommodity = null 
    }
  }
}
</script>

<style>

</style>