<template>
  <div>
    <TopNavbar :nav-link="store.mainNavigationURL"/>

    <div class="container">
      <div class="row">
        <div class="col qq-container">

          <div class="mt-5">
            <h1 class="color-neutral">Enter DOT number.</h1>
          </div>

          <div>

            <div class="form-floating mt-4">
              <input
                  type="number"
                  :class="['form-control', { 'is-invalid': v$.dotVal.$errors.length }]"
                  placeholder="DOT Number"
                  v-model="store.submissionData.dot"
                  autofocus
              />
              <label>DOT Number</label>
              <div v-show="v$.dotVal.$errors.length" class="feedback invalid-feedback">
                DOT must be an integer between 5 and 8 digits.
              </div>
            </div>
          </div>

          <div class="text-center mt-5">
            <button class="btn btn-link link" @click="noDot">
              I'm missing the policyholder's DOT.
            </button>
          </div>


          <!-- Back and Next Buttons -->
          <div class="row mt-5">
            <div class="col-6">
              <button class="btn btn-default w-100 mt-5"
                      @click="back">
                Back
              </button>
            </div>
            <div class="col-6">
              <button class="btn btn-primary w-100 mt-5"
                      :disabled="disableNext"
                      @click="next">
                Next
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {quickQuoteStore} from "@/stores/quickQuoteStore";
import {useVuelidate} from '@vuelidate/core'
import {required, minLength, maxLength, numeric} from '@vuelidate/validators'
import TopNavbar from "@/components/TopNavbar.vue";

export default {
  components: {TopNavbar},
  data() {
    return {
      dotVal: '',
    }
  },
  setup() {
    const store = quickQuoteStore();
    return {store, v$: useVuelidate()};
  },
  validations() {
    return {
      dotVal: {required, numeric, minLength: minLength(5), maxLength: maxLength(8)},
    }
  },
  computed: {
    disableNext() {
      return !this.store.submissionData.dot
    }
  },
  methods: {
    async checkDot() {
      //  Save values into Validations Checkers
      this.dotVal = this.store.submissionData.dot

      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      //  Get Dot Data from api
      await this.store.getDotData(this.store.submissionData.dot);

      // Go to next view
      this.$router.push("dotdata");
    },
    // User does not have DOT
    async noDot() {
      // clears data if any
      await this.store.resetData();

      // Save data as manual dot data
      this.store.submissionData.manualDot = true;

      this.$router.push("dotdata");
    },
    async back() {
      this.$router.push(`/${this.store.userId}`);
    },
    async next() {
      await this.checkDot();
    }
  }
};
</script>
