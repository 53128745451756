import {defineStore} from 'pinia'
import axios from 'axios'

const AUTH0_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID;
const AUTH0_CLIENT_SECRET = process.env.VUE_APP_AUTH0_CLIENT_SECRET;
const AUTH0_AUDIENCE = process.env.VUE_APP_AUTH0_CLIENT_AUDIENCE;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        authToken: ''
    }),
    persist: {
        storage: sessionStorage,
    },
    actions: {
        // Get an auth token
        async getToken() {
            await
                axios.post(`https://${AUTH0_DOMAIN}/oauth/token`,
                    {
                        client_id: AUTH0_CLIENT_ID,
                        client_secret: AUTH0_CLIENT_SECRET,
                        audience: AUTH0_AUDIENCE,
                        grant_type: "client_credentials"
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((res) => {
                        if (res.data && res.data.access_token) {
                            this.authToken = res.data.access_token
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
        },
        clear() {
            this.authToken = '';
        }
    }
})
