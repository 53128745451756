<template>
	<div>
		<TopNavbar/>
		<!-- Progress bar -->
		<ProgressBar />

		<!-- Main container -->
		<div v-if="isMounted" class="container">
			<div class="row">
				<div class="fq-container">
					<div class="row">

						<!-- LeftNavbar bar -->
						<LeftNavbar />

						<!-- Main content -->
						<div class="col content-scrollable">
							<!--  Top Tittle -->
							<h1 class="color-primary">🏁 Finalize Quote</h1>
							<p class="fontbody3">Revise, add details about the future policyholder's business, and modify coverage.</p>

							<!-- Accordion Content -->
							<Accordion :multiple="true" :activeIndex="[0, 1, 2, 3, 4, 5]" expandIcon="pi pi-chevron-down"
								collapseIcon="pi pi-chevron-up" class="finalize-quote-accordion mt-5">

								<!-- Policy Holder -->
								<AccordionTab header="Policyholder">
									<div id="policyholder_section">
										<BusinessInfoSection />
										<AddressSection />
									</div>
								</AccordionTab>

								<!-- Operations -->
								<AccordionTab header="Operations">
									<div id="operations_section">
										<OperationTypeSection />
										<CargoSection />
										<RadiusSection />
										<RefrigerationExcludedOperationSection />
									</div>
								</AccordionTab>

								<!-- Equipment -->
								<AccordionTab header="Equipment">
									<div id="equipment_section">
										<TrucksSection />
										<TrailersSection />
									</div>
								</AccordionTab>

								<!-- Drivers -->
								<AccordionTab header="Drivers">
									<div id="drivers_section">
										<DriversSection />
									</div>
								</AccordionTab>

								<!-- Coverages -->
								<AccordionTab header="Coverages">
									<div id="coverages_section"></div>
								</AccordionTab>

							</Accordion>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mainStore as main } from "@/stores/mainStore"
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore"
import TopNavbar from '@/components/TopNavbar.vue';
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import ProgressBar from '@/components/ProgressBar'
import LeftNavbar from '@/components/LeftNavbar'
// Policy Holder Components
import BusinessInfoSection from '@/components/FinalizeQuote/PolicyHolder/BusinessInfoSection'
import AddressSection from '@/components/FinalizeQuote/PolicyHolder/AddressSection'
// Operations Components
import OperationTypeSection from '@/components/FinalizeQuote/Operations/OperationTypeSection'
import CargoSection from '@/components/FinalizeQuote/Operations/CargoSection'
import RadiusSection from '@/components/FinalizeQuote/Operations/RadiusSection'
import RefrigerationExcludedOperationSection from '@/components/FinalizeQuote/Operations/RefrigerationExcludedOperationsSection'
// Equipment Components
import TrucksSection from '@/components/FinalizeQuote/Equipment/TrucksSection'
import TrailersSection from '@/components/FinalizeQuote/Equipment/TrailersSection'
// Drivers Section
import DriversSection from '@/components/FinalizeQuote/Drivers/DriversSection'


export default {
  data() {
    return {
      isMounted: false
    }
  },
  setup() {
    const store = finalizeQuoteStore()
		const mainStore = main()
    return { store, mainStore }
  },
  async beforeCreate() {
    await this.store.getQuoteData(this.$route.params.id)
	await this.mainStore.getStates()
    this.isMounted = true
  },
	components: {
		TopNavbar,
		Accordion,
		AccordionTab,
		ProgressBar,
		LeftNavbar,
		BusinessInfoSection,
		AddressSection,
		OperationTypeSection,
		CargoSection,
		RadiusSection,
		RefrigerationExcludedOperationSection,
		TrucksSection,
		TrailersSection,
		DriversSection
	}
}
</script>

<style>
.fq-container{
  margin: auto;
  max-width: 1200px;
  margin-top: 170px!important;
}
@media (max-width: 768px){
.fq-container{
	margin-top: 300px!important;
}
}
</style>