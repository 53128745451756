<template>
  <div>
    <TopNavbar :nav-link="store.mainNavigationURL"/>

    <div class="container">
      <div class="row">
        <div class="col qq-container">

          <!-- if VIN found -->
          <div v-if="store.submissionData.equipmentVin" class="mt-5">
            <h1>Confirm vehicle details</h1>
            <p>
              Verify information or edit as necessary.
            </p>
          </div>

          <!-- If manual data -->
          <div v-else class="mt-5">
            <h1>Enter vehicle details</h1>
            <p>
              Provide year, make, model, and value to proceed.
            </p>
          </div>

          <div>
            <!-- Equipment Year -->
            <div class="form-floating mt-4">
              <input
                  type="number"
                  maxlength="4"
                  minlength="4"
                  placeholder="Year"
                  :class="['form-control', { 'is-invalid': v$.equipmentYearVal.$errors.length }]"
                  v-model="store.submissionData.equipmentYear"
              />
              <label>Year</label>
              <div v-show="v$.equipmentYearVal.$errors.length" class="feedback invalid-feedback">
                Year must be an integer with 4 digits and greater than 1980.
              </div>
            </div>

            <!-- Equipment Make -->
            <div class="form-floating mt-4">
              <input
                  type="text"
                  placeholder="Make"
                  :class="['form-control', { 'is-invalid': v$.equipmentMakeVal.$errors.length }]"
                  v-model="store.submissionData.equipmentMake"
              />
              <label>Make</label>
              <div v-show="v$.equipmentMakeVal.$errors.length" class="feedback invalid-feedback">
                Make must be a valid vehicle make name.
              </div>
            </div>

            <!-- Equipment Model -->
            <div class="form-floating mt-4">
              <input
                  type="text"
                  placeholder="Model"
                  :class="['form-control', { 'is-invalid': v$.equipmentModelVal.$errors.length }]"
                  v-model="store.submissionData.equipmentModel"
              />
              <label>Model</label>
              <div v-show="v$.equipmentModelVal.$errors.length" class="feedback invalid-feedback">
                Model must be a valid vehicle model name.
              </div>
            </div>

            <!-- Equipment Value -->
            <div class="form-floating mt-4">
              <input
                  :class="['form-control', { 'is-invalid': v$.equipmentValueVal.$errors.length }]"
                  type="text"
                  :value="vueNumberFormat(store.submissionData.equipmentValue)"
                  @keyup="formatNumber"/>
              <label>Value</label>
              <div v-show="v$.equipmentValueVal.$errors.length" class="feedback invalid-feedback">
                Value must be a valid number greater than $5000.
              </div>
            </div>
          </div>

          <!-- Back and Next Buttons -->
          <div class="row mt-5">
            <div class="col-6">
              <button class="btn btn-default w-100 mt-5"
                      @click="back">
                Back
              </button>
            </div>
            <div class="col-6">
              <button class="btn btn-primary w-100 mt-5"
                      :disabled="disableNext"
                      @click="next">
                Next
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {quickQuoteStore} from "@/stores/quickQuoteStore";
import {useVuelidate} from '@vuelidate/core'
import {maxLength, minLength, minValue, numeric, required} from '@vuelidate/validators'
import TopNavbar from "@/components/TopNavbar.vue";

export default {
  components: {TopNavbar},
  data() {
    return {
      equipmentYearVal: '',
      equipmentMakeVal: '',
      equipmentModelVal: '',
      equipmentValueVal: '',
    }
  },
  setup() {
    const store = quickQuoteStore();
    return {store, v$: useVuelidate()};
  },
  validations() {
    return {
      equipmentYearVal: {required, numeric, minLength: minLength(4), maxLength: maxLength(4), minValue: minValue(1980)},
      equipmentMakeVal: {required},
      equipmentModelVal: {required},
      equipmentValueVal: {required, numeric, minValue: minValue(5000)}
    }
  },
  computed: {
    disableNext() {
      if (!this.store.submissionData.equipmentYear) {
        return true
      }
      if (!this.store.submissionData.equipmentMake) {
        return true
      }
      if (!this.store.submissionData.equipmentModel) {
        return true
      }
      if (!this.store.submissionData.equipmentValue) {
        return true
      }
      return false
    }
  },
  methods: {
    async checkEquipmentData() {
      //  Save values into Validations Checkers
      this.equipmentYearVal = this.store.submissionData.equipmentYear
      this.equipmentMakeVal = this.store.submissionData.equipmentMake
      this.equipmentModelVal = this.store.submissionData.equipmentModel
      this.equipmentValueVal = this.store.submissionData.equipmentValue

      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      // Go to next view
      this.$router.push('yearsonequipment')

    },
    async searchEquipment() {
      //  Save values into Validations Checkers
      this.equipmentYearVal = this.store.submissionData.equipmentYear
      this.equipmentMakeVal = this.store.submissionData.equipmentMake
      this.equipmentModelVal = this.store.submissionData.equipmentModel
      this.equipmentValueVal = this.store.submissionData.equipmentValue

      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.searchForVehicleData(this.equipmentYearVal, this.equipmentModelVal, this.equipmentMakeVal);

      if (this.store.submissionData.equipmentFound === false) {
        return
      }
      this.equipmentFound = true

      // Go to next view
      this.$router.push('yearsonequipment')
    },
    async back() {
      this.$router.push('vin')
    },
    async next() {
      await this.checkEquipmentData()
    },
    formatNumber(event) {
      const inputValue = this.vueNumberFormat(event.target.value);
      this.store.submissionData.equipmentValue = parseFloat(inputValue.replace('$', '').replace(',', ''))
    }
  }
}
</script>
