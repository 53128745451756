<template>
  <div>
    <TopNavbar :nav-link="store.mainNavigationURL"/>

    <div class="container">
      <div class="row">
        <div class="col qq-container">
          <!-- Run loader on page load -->
          <Loader class="mt-5" v-if="runLoader"/>

          <!-- if response is declined  CREATE COMPONENT FOR IT-->
          <div v-else-if="this.quote.status === 'Declined'">
            <div class="mt-5">
              <h1 class="color-neutral">Applicant declined</h1>
              <p>
                Sorry! This applicant has been declined for the following reasons:
              </p>
              <ul class="mt-5">
                <li
                    v-for="reason in this.quote.reason"
                    v-bind:key="reason"
                    v-show="reason.length"
                    class="fontbody3 mt-3">
                  <div>{{ reason }}</div>
                </li>
              </ul>
            </div>
            <!-- Button start Over -->
            <div class="mt-5">
              <button
                  @click="this.$router.push(this.restartURL)"
                  class="btn btn-primary w-100">
                Try another applicant
              </button>
            </div>
          </div>

          <!-- if response is Errored  CREATE COMPONENT FOR IT-->
          <div v-else-if="this.quote.status === 'Errored'">
            <div class="mt-5">
              <h1>Quote Errored.</h1>
              <p>
                We ran into an issue processing this quote request. Please validate your entries and retry your request.
              </p>
            </div>
            <!-- Button start Over -->
            <div class="mt-5">
              <button
                  @click="this.$router.push(this.restartURL)"
                  class="btn btn-primary w-100">
                Get another quote
              </button>
            </div>
          </div>

          <!-- If response is Quoted -->
          <div v-else-if="this.quote.status === 'Quoted'">
            <div class="mt-5">
              <h1>Preview your price</h1>
              <p>
                Check the cost breakdown before finalizing your submission.
              </p>
            </div>

            <!-- Preview Quote -->
            <div>
              <a :href="this.quote.quote_pdf" target="_blank" class="btn btn-link">
                <span> <i class="pi pi-file-pdf"></i> Quote_letter.pdf</span>
              </a>
            </div>

            <!-- Quote and policies -->
            <div class="mt-1 border-div">
              <div>
                <h4>Submission #{{ this.quote.submission_number }}</h4>
              </div>

              <ol class="mb-0 mt-4">
                <!-- Tittles -->
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <span class="fontbody5">COVERAGE</span>
                  <span class="fontbody5">PREMIUM</span>
                </li>

                <!-- Policies -->
                <li
                    v-for="(coverage, coverageName) in this.quote.coverages"
                    v-bind:key="coverage"
                    class="list-group-item d-flex justify-content-between align-items-start fontbody3 mt-3">
                  <span>{{ this.getFullCoverageName(coverageName) }}</span>

                  <span v-if="coverage">{{ vueNumberFormat(coverage.totalCost, {}) }}</span>
                  <span v-else>{{ vueNumberFormat(0, {}) }}</span>
                </li>

                <!-- Total Submission -->
                <li class="list-group-item d-flex justify-content-between align-items-start fontbody3 li-divider mt-3">
                  <h3 class="mb-0">Total</h3>
                  <h3 class="mb-0">
                    {{ vueNumberFormat(this.quote.totalQuote, {}) }}
                  </h3>
                </li>
              </ol>

              <p class="fontbody5 pt-2">*Subject to change based on driver and vehicle records.</p>

            </div>

            <!-- Buttons CTA Start Over Go to Submission -->
            <div class="my-4">

              <div class="row">
                <div class="col-6">
                  <button
                      @click="this.$router.push(this.restartURL)"
                      class="btn btn-default w-100">
                    Start Over
                  </button>
                </div>
                <div class="col-6">
                  <a :href="this.reviseQuoteURL">
                    <button class="btn btn-primary w-100">
                      Finalize
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <p class="fontbody5">
              See quote letter for pricing details. We require some additional
              information about the future policyholder before binding. You can
              revise this quote if need be.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {quickQuoteStore} from "@/stores/quickQuoteStore";
import Loader from "@/components/LoaderFiles";
import {useAuthStore} from "@/stores/authStore";
import moment from "moment";
import TopNavbar from "@/components/TopNavbar.vue";

export default {
  components: {
    TopNavbar,
    Loader,
  },
  data() {
    const today = moment();
    return {
      runLoader: true,
      quote: {},
      quoteLetter: "",
      reviseQuoteURL: '',
      restartURL: '',
      effectiveDate: today.format('MM/DD/YYYY')
    };
  },
  setup() {
    const store = quickQuoteStore();
    const authStore = useAuthStore();
    return {store, authStore};
  },
  methods: {
    async getQuote() {

      this.restartURL = `/${this.store.userId}`;

      // Check if there is submission data else return to page Welcome
      if (!Object.keys(this.store.submissionData).length) {
        this.store.$reset
        this.$router.push("/")
        return;
      }

      // Check if quote have been requested already
      if (this.store.submissionData.quoteRequested !== true) {
        await this.store.buildSubmission();
        await this.store.getQuote();
      }

      this.quote = this.store.quoteResponse;

      // If quoted
      if (this.quote.status === "Quoted") {

        //  Get URL to submission
        this.reviseQuoteURL = `${this.store.platformURL}/transportation/${this.quote.submission_number}/0/edit`;
      }

      // Stop running the loader
      this.runLoader = false

      // Resets all data
      await this.store.resetData()
      await this.authStore.clear()

    },
    getFullCoverageName(coverageName) {
      const coverages = {
        'al': 'Auto Liability',
        'apd': 'Auto Physical Damage',
        'mtc': 'Motor Truck Cargo',
        'tgl': 'Trucker\'s General Liability',
        'ntl': 'Non-Trucking Liability',
      };
      return (coverages[coverageName] || coverageName);
    },
  },
  created() {
    // Prevents user from clicking back button
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
  },
  beforeMount() {
    this.getQuote();
  }

};
</script>
