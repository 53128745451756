<template>
  <div class="accordion-conponent">

    <!-- Refrigeration Section -->
    <div>
      <!-- Tittle -->
      <h3 class="mb-0">Refrigeration</h3>
      <p class="fontbody3">Does the future policy holder haul any refrigerated goods?</p>

      <div class="row">
        <div class="col-lg-12 col-xl-8">
          <p>Please select one:</p>
          <!-- Cargo Options -->
          <div class="form-check radius-input mt-3">
            <input
              type="radio"
              name="haul_type"
              class="form-check-input"
            />
            <label class="form-check-label"> Yes, the future policyholder hauls refrigerated goods.</label>
          </div>
          <div class="form-check radius-input mt-4">
            <input
              type="radio"
              name="haul_type"
              class="form-check-input"
            />
            <label class="form-check-label"> No, the future policyholder does not haul refrigerated goods.</label>
          </div>
        </div>
      </div> 
    </div>

    <!-- Excluded operations Section -->
    <div class="mt-4">
      <!-- Tittle -->
      <h3 class="mb-0">Excluded operations</h3>
      <p class="fontbody3">Does the future policyholder carry out any of the excluded operations?</p>

      <div class="row">
        <div class="col-lg-12 col-xl-8">
          <div class="my-3"><a class="btn-link">View Full List</a></div>
          
          <p>Please select one:</p>
          <!-- Cargo Options -->
          <div class="form-check radius-input mt-3">
            <input
              type="radio"
              name="haul_type"
              class="form-check-input"
            />
            <label class="form-check-label"> Yes, the future policyholder conducts one or more excluded operations.</label>
          </div>
          <div class="form-check radius-input mt-4">
            <input
              type="radio"
              name="haul_type"
              class="form-check-input"
            />
            <label class="form-check-label"> No, the future policyholder does not conduct any excluded operations.</label>
          </div>
        </div>
      </div> 
    </div>

     <!-- Cancelation Section -->
     <div class="mt-4">
      <!-- Tittle -->
      <h3 class="mb-0">Cancellation</h3>
      <p class="fontbody3">Has the future policyholder’s insurance ever been cancelled or non-renewed by any insurer in the past 3 years (for any reasons NOT related to payment of premium)?</p>

      <div class="row">
        <div class="col-lg-12 col-xl-8">
          <p>Please select one:</p>
          <!-- Cargo Options -->
          <div class="form-check radius-input mt-3">
            <input
              type="radio"
              name="haul_type"
              class="form-check-input"
            />
            <label class="form-check-label"> Yes, the future policyholder has been cancelled or non-renewed in the past 3 years.</label>
          </div>
          <div class="form-check radius-input mt-4">
            <input
              type="radio"
              name="haul_type"
              class="form-check-input"
            />
            <label class="form-check-label"> No, the future policyholder has not been cancelled or non-renewed in the past 3 years.</label>
          </div>
        </div>
      </div> 
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>