<template>
  <div>
    <TopNavbar :nav-link="store.mainNavigationURL"/>

    <div class="container">
      <div class="row">
        <div class="col qq-container">

          <div class="mt-5">
            <h1>Enter VIN</h1>
          </div>

          <!-- check vin number -->
          <div>
            <div class="form-floating mt-4">
              <input
                  type="text"
                  :class="['form-control', { 'is-invalid': v$.equipmentVinVal.$errors.length }]"
                  placeholder=""
                  maxlength="17"
                  v-model="store.submissionData.equipmentVin"
                  autofocus
              />
              <label>Vehicle Identification Number</label>
              <div v-show="v$.equipmentVinVal.$errors.length" class="feedback invalid-feedback">
                VIN must be 17 characters.
              </div>

            </div>

            <div class="text-center mt-5">
              <button class="btn btn-link link" @click="noVin">
                I’m missing the VIN.
              </button>
            </div>

            <!-- Back and Next Buttons -->
            <div class="row mt-5">
              <div class="col-6">
                <button class="btn btn-default w-100 mt-5"
                        @click="back">
                  Back
                </button>
              </div>
              <div class="col-6">
                <button class="btn btn-primary w-100 mt-5"
                        :disabled="disableNext"
                        @click="next">
                  Next
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {quickQuoteStore} from "@/stores/quickQuoteStore";
import {useVuelidate} from '@vuelidate/core'
import {required, minLength, maxLength} from '@vuelidate/validators'
import TopNavbar from "@/components/TopNavbar.vue";

export default {
  components: {TopNavbar},
  data() {
    return {
      vinSelected: false,
      equipmentVinVal: '',
    }
  },
  setup() {
    const store = quickQuoteStore();
    return {store, v$: useVuelidate()};
  },
  computed: {
    disableNext() {
      return this.store.submissionData.equipmentVin === '' ||
          this.store.submissionData.equipmentVin === null ||
          this.store.submissionData.equipmentVin === undefined
    }
  },
  validations() {
    return {
      equipmentVinVal: {required, minLength: minLength(17), maxLength: maxLength(17)},
    }
  },
  methods: {
    async checkVin() {
      //  Save values into Validations Checkers
      this.equipmentVinVal = this.store.submissionData.equipmentVin

      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      //  Get Vin Data from api
      await this.store.getVinData(this.store.submissionData.equipmentVin)

      // Go to next view
      this.$router.push("vindata");
    },
    async noVin() {
      // clears Equipment data if any
      await this.store.resetVINData();

      // Save data as manual Equipment data
      this.store.submissionData.manualVdata = true;

      // Goes to next view
      this.$router.push("vindata");
    },
    async back() {
      this.$router.push("haultype");
    },
    async next() {
      await this.checkVin()
    },
  },
};
</script>

