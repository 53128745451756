<template>
  <div class="accordion-conponent">
    <!-- Tittle -->
    <h3>Address</h3>
    <p class="fontbody3">Seconday copy is needed.</p>

    <div class="row">

      <!-- Address Options -->
      <div class="col-lg-6 col-xl-4 mt-4" v-for="address in store.submissionData.address" :key="address.type">
        <div class="card address-card">
          <div @click="editAddress(address.type)" class="edit-menu clickable">
            <i class="pi pi-pencil"></i>
          </div>
          <div class="card-body">
            <p class="card-title fontbody5 mb-4">{{ address.type }}</p>
            <p class="fontbody2">{{ address.address }}</p>
            <p class="fontbody2"></p>
            <p class="fontbody2 mb-0">{{ address.city }}, {{ address.state }} {{ address.zip }}</p>
          </div>
        </div>
      </div>

    </div>
    
    <!-- modals -->
    <AddressModal />
  </div>
</template>

<script>
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore"
import AddressModal from '@/components/FinalizeQuote/PolicyHolder/AddressModal'

export default {
  components: {
    AddressModal
  },
  setup() {
    const store = finalizeQuoteStore()
    return { store }
  },
  methods: {
    editAddress(type) {
      this.store.addressModal.title = type + ' address'
      this.store.addressModal.type = type
      this.store.addressModal.callMethod = 'update'
      this.store.addressModal.show = true
    }
  }
}
</script>

<style>

</style>