<template>
  <div>
    <TopNavbar :nav-link="store.mainNavigationURL"/>

    <div class="container">
      <div class="row">
        <div class="col qq-container">

          <div class="mt-5">
            <h1>Select driver experience</h1>
            <p>
              Years of experience driving this type of vehicle.
            </p>
          </div>

          <div :class="{'checked' : yearsOnEquipment === '1'}" class="form-check radius-input mt-4">
            <input
                type="radio"
                name="years_on_equipment"
                class="standard-radio-input"
                v-model="yearsOnEquipment"
                value="1"
            />
            <label class="form-check-label"> Under 2 years </label>
          </div>

          <div :class="{'checked' : yearsOnEquipment === '3'}" class="form-check radius-input  mt-4">
            <input
                type="radio"
                name="years_on_equipment"
                class="standard-radio-input"
                v-model="yearsOnEquipment"
                value="3"
            />
            <label class="form-check-label"> 2 - 4 years</label>
          </div>

          <div :class="{'checked' : yearsOnEquipment === '5'}" class="form-check radius-input mt-4">
            <input
                type="radio"
                name="years_on_equipment"
                class="standard-radio-input"
                v-model="yearsOnEquipment"
                value="5"
            />
            <label class="form-check-label"> 5+ years </label>
          </div>

          <!-- Back and Next Buttons -->
          <div class="row mt-5">
            <div class="col-6">
              <button class="btn btn-default w-100 mt-5"
                      @click="back">
                Back
              </button>
            </div>
            <div class="col-6">
              <button class="btn btn-primary w-100 mt-5"
                      :disabled="disableNext"
                      @click="next">
                Next
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {quickQuoteStore} from "@/stores/quickQuoteStore";
import TopNavbar from "@/components/TopNavbar.vue";

export default {
  components: {TopNavbar},
  data() {
    return {
      yearsOnEquipment: '0'
    }
  },
  setup() {
    const store = quickQuoteStore();
    return {store};
  },
  computed: {
    disableNext() {
      return this.store.submissionData.yearsOnEquipment === '0' ||
          this.store.submissionData.yearsOnEquipment === undefined
    }
  },
  watch: {
    yearsOnEquipment(newYearsOnEquipmentVal) {
      this.store.submissionData.yearsOnEquipment = newYearsOnEquipmentVal
    }
  },
  methods: {
    async back() {
      await this.$router.push("vindata")
    },
    async next() {
      await this.$router.push("policyholderage")
    },
  }
};
</script>
