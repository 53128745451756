<template>
  <div class="accordion-conponent separator">
    <!-- Tittle -->
    <h3>Radius</h3>
    <p class="fontbody3">The distance regularly traveled from garaging address when conducting business. Total percentage should equal 100%.</p>

    <div class="row">

      <!-- Cargo Options -->
      <div class="col-md-6 col-xl-3  mt-4" v-for="radius in radiusOptions" :key="radius.value">
        <div class="card cargo-card">
          <div class="card-body">
            <h5 class="card-subtitle">{{ radius.name }}</h5>
            <div class="mt-4 cargo-input">
              <select v-model="radius.value" class="form-control">
                <option v-for="n in 100" v-bind:key="n">{{ n }}</option>
              </select>
              <div class="percentage-icon">%</div>
            </div>    
          </div>
        </div>
      </div>

    </div> 
  </div>
</template>

<script>
export default {
  data(){
  return{
    selected: '',
    radiusOptions: [
        { name: 'Under 50 Miles', value:'50'},
        { name: '51-200 Miles', value:'25' },
        { name: '201-500 Miles', value:'0' },
        { name: 'Over 500 Miles', value:'25' },
      ]
  }
},
}
</script>

<style>

</style>