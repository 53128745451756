<template>
  <div>
    <Dialog 
    :header="store.driverModal.title" 
    v-model:visible="store.driverModal.show" 
    :style="{width: '480px'}" 
    :modal="true"
    >
      <div>
        <div class="mb-3">
          <label for="name" class="form-label-font mb-1">Full name</label>
          <input 
            v-model="driver.name" 
            :class="['form-control', { 'is-invalid': v$.driver.name.$errors.length }]" 
            type="text" 
            name="name" 
            id="name"
          />
        </div>
        <div class="mb-3">
          <label for="dateOfBirth" class="form-label-font mb-1">Date of birth</label>
          <input 
            v-model="driver.dateOfBirth" 
            :class="['form-control', { 'is-invalid': v$.driver.dateOfBirth.$errors.length }]" 
            type="text" 
            name="dateOfBirth" 
            id="dateOfBirth"
          />
        </div>
        <div class="mb-3">
          <label for="yearsExperience" class="form-label-font mb-1">Years of experience</label>
          <input 
            v-model="driver.yearsExperience" 
            :class="['form-control', { 'is-invalid': v$.driver.yearsExperience.$errors.length }]" 
            type="text" 
            name="yearsExperience" 
            id="yearsExperience"
          />
        </div>
        <div class="mb-3">
          <label for="licenseNumber" class="form-label-font mb-1">Drivers license</label>
          <input 
            v-model="driver.licenseNumber" 
            :class="['form-control', { 'is-invalid': v$.driver.licenseNumber.$errors.length }]" 
            type="text" 
            name="licenseNumber" 
            id="licenseNumber"
          />
        </div>
        <div class="mb-3">
          <label for="licenseState" class="form-label-font mb-1">State</label>
          <select v-model="driver.licenseState" name="licenseState" id="licenseState" :class="['form-select', { 'is-invalid': v$.driver.licenseState.$errors.length }]" >
            <option value="">Select State</option>
            <option v-for="(state, index) in mainStore.states" :key="index" :value="index">
              {{ state }}
            </option>
          </select>
        </div>
        <div>
          <button type="button" class="btn btn-primary w-100" @click="this[store.driverModal['callMethod']]()">Save</button>
        </div>
      </div>
    </Dialog>
</div>
</template>
    
<script>
import Dialog from 'primevue/dialog/sfc'
import { mainStore as main } from "@/stores/mainStore"
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  data() {
    return {
      driver: {
        name: null,
        dateOfBirth: null,
        yearsExperience: null,
        licenseNumber: null,
        licenseState: null
      }
    }
  },
  setup() {
    const store = finalizeQuoteStore()
    const mainStore = main()
    return { store, mainStore, v$: useVuelidate() }
  },
  validations() {
    return {
      driver: {
        name: { required },
        dateOfBirth: { required },
        yearsExperience: { required },
        licenseNumber: { required },
        licenseState: { required }
      }
    }
  },
  components: {
    Dialog
  },
  watch: {
    'store.driverModal.show': function () {
      if (this.store.driverModal.show && this.store.driverModal.callMethod == 'update') {
        this.driver = this.store.submissionData.drivers[this.store.selectedDriverToUpdate]
      } else {
        this.resetForm()
      }
    },
  },
  methods: {
    resetForm() {
      // Clear form fields
      this.driver = {
        name: null,
        dateOfBirth: null,
        yearsExperience: null,
        licenseNumber: null,
        licenseState: null
      }
      // Reset validation
      this.v$.$reset()
    },
    async update() {
      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.updateDriver(this.driver)
      this.store.driverModal.show = false

      this.resetForm()
    },
    async save() {
      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.saveDriver(this.driver)
      this.store.driverModal.show = false

      this.resetForm()
    }
  }
}
</script>