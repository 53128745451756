<template>
  <div class="accordion-conponent separator">
    
    <!-- Tittle -->
    <h3>Operation Type</h3>
    <p class="fontbody3">What is the best description of the future policyholder’s operations? Select all that apply.</p>

    <!-- Checkbox List -->
    <div>
      <div
        v-for="operationType in OperationsType" :key="operationType.value" 
        class="form-check form-check-inline mt-3 mx-2"
      >
        <label 
          :class="['form-check-label', { 'checked': store.submissionData.operations.includes(operationType.value) }]"
          :for="operationType.value"
        >
          <input 
            class="form-check-input" 
            type="checkbox" :id="operationType.value" 
            :checked="store.submissionData.operations.includes(operationType.value)" 
            :value="operationType.value" 
            @change="updateOperation(operationType.value)"
          >
          {{ operationType.name }}
        </label>
      </div>
    </div>

  </div>
</template>

<script>
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore"

export default {
  data() {
    return {
      OperationsType: [
        { name: 'Authorized For Hire',          value:'auth_for_hire' },
        { name: 'Exempt For Hire',              value:'exempt_for_hire' },
        { name: 'Dump Truck',                   value:'dump_truck' },
        { name: 'Household Goods Mover',        value:'household_goods_mover' },
        { name: 'Oversized or Overweight',      value:'oversized_or_overweight' },
        { name: 'Interstate',                   value:'interstate' },
        { name: 'Intrastate Only (Non-Hazmat)', value:'intrastate_only' },
        { name: 'Private (Property)',           value:'private_property' },
        { name: 'Tanker',                       value:'tanker' },
        { name: 'Intermodal (UIIA) ',           value:'intermodal_uiia' },
        { name: 'Logging',                      value:'logging' },
        { name: 'Automobile Hauler',            value:'automobile_hauler' },
        { name: 'Hot Shot',                     value:'hot_shot' },
      ]
    };
  },
  setup() {
    const store = finalizeQuoteStore()
    return { store }
  },
  methods: {
    async updateOperation(operation) {
      if (this.store.submissionData.operations.includes(operation)) {
        await this.store.removeOperation(operation)
      } else {
        await this.store.addOperation(operation)
      }
    }
  }
}
</script>
