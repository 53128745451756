<template>
  <div class="accordion-conponent">
    <!-- Tittle -->
    <h3>Drivers</h3>
    <p class="fontbody3">Seconday copy is needed.</p>

    <div class="row">

      <!-- Drivers Options -->
      <div class="col-lg-6 col-xl-4 mt-4" v-for="(driver, index) in store.submissionData.drivers" :key="index">
        <div class="card driver-card">

          <!-- Card body -->
          <div class="card-body">
            <p class="card-title text-uppercase fontbody5 mb-3">DRIVER</p>
            <h4 class="mb-3">{{ driver.name }} {{ calculateAge(driver.dateOfBirth) }}</h4>
            <p class="fontbody3">{{ driver.licenseState }} {{ driver.licenseNumber }}</p>

            <!-- Overlay-menu-->
            <div @click="this.$refs.driverMenu[0].toggle($event)" class="edit-menu clickable">
              <i class="pi pi-ellipsis-v"></i>
              <Menu ref="driverMenu" class="overlay-menu" :popup="true">
                <template #start>
                  <ul>
                    <li @click="edit(index)">Edit</li>
                    <li @click="remove(index)">Remove</li>
                  </ul>
                </template>
              </Menu>
            </div>
          </div>

        </div>
      </div>

       <!-- ADD TRAILERS CARD -->
       <div class="col-lg-6 col-xl-4  mt-4">
        <div class="card driver-card clickable" @click="add">
          <div class="card-body">
            <div class="text-center mt-1 color-primary">
              <i class="pi pi-plus-circle" style="font-size: 2rem"></i>
              <h5 class="mt-2">Add Drivers</h5>
              <p class="fontbody5">You can add as many drivers as needed.</p>
            </div>    
          </div>
        </div>
      </div>

      <!-- Modals -->
      <ModalAddDriver />

    </div> 
  </div>
</template>

<script>
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore"
import ModalAddDriver from '@/components/FinalizeQuote/Drivers/ModalAddDriver'
import Menu from 'primevue/menu'

export default {
  components: {
    ModalAddDriver,
    Menu
  },
  data() {
    return {}
  },
  setup() {
    const store = finalizeQuoteStore()
    return { store }
  },
  methods: {
    add() {
      this.store.driverModal.title = 'Add driver'
      this.store.driverModal.callMethod = 'save'
      this.store.driverModal.show = true
    },
    edit(index) {
      this.store.selectedDriverToUpdate = index
      this.store.driverModal.title = 'Edit driver'
      this.store.driverModal.callMethod = 'update'
      this.store.driverModal.show = true
    },
    remove(index) {
      this.store.removeDriver(index)
    },
    calculateAge(dob) {
      let currentDate = new Date();
      let birthDate = new Date(dob);
      let difference = currentDate - birthDate;
      let age = Math.floor(difference/31557600000);
      return age
    }
  }
}
</script>

<style>
.card.driver-card{
  height: 156px;
}
</style>