<template>
  <div>
    <Dialog 
      :header="store.contactModal.title" 
      v-model:visible="store.contactModal.show" 
      :style="{width: '480px'}" 
      :modal="true"
    >
      <div>
        <div class="mb-3">
          <label for="first_name" class="form-label-font mb-1">First name</label>
          <input 
            v-model="contactInfo.firstName" 
            :class="['form-control', { 'is-invalid': v$.contactInfo.firstName.$errors.length }]" 
            type="text" 
            name="first_name" 
            id="first_name"
          />
        </div>
        <div class="mb-3">
          <label for="last_name" class="form-label-font mb-1">Last name</label>
          <input 
            v-model="contactInfo.lastName"
            :class="['form-control', { 'is-invalid': v$.contactInfo.lastName.$errors.length }]"  
            type="text" 
            name="last_name" 
            id="last_name" 
          />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label-font mb-1">Phone</label>
          <input 
            v-model="contactInfo.phone"
            :class="['form-control', { 'is-invalid': v$.contactInfo.phone.$errors.length }]" 
            type="text" 
            name="phone" 
            id="phone"
          />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label-font mb-1">Email</label>
          <input 
            v-model="contactInfo.email"
            :class="['form-control', { 'is-invalid': v$.contactInfo.email.$errors.length }]" 
            type="text" 
            name="email"
            id="email"
          />
        </div>
        <div>
          <button type="button" class="btn btn-primary w-100" @click="this[store.contactModal['callMethod']]()">Save</button>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog/sfc'
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  components: {
    Dialog
  },
  data() {
    return {
      contactInfo: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      }
    }
  },
  setup() {
    const store = finalizeQuoteStore()
    return { store, v$: useVuelidate() }
  },
  validations() {
    return {
      contactInfo: {
        firstName: { required },
        lastName: { required },
        phone: { required },
        email: { required }
      }
    }
  },
  watch: {
    'store.contactModal.show': function () {
      if (this.store.contactModal.show && this.store.contactModal.callMethod == 'update') {
        this.contactInfo = this.store.contactInfo
      }
    },
  },
  methods: {
    async update() {
      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.updateContactInfo(this.contactInfo)
      this.store.contactModal.show = false
    },  
    async save() {
      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.saveContactInfo(this.contactInfo)
      this.store.contactModal.show = false

      // Clear form fields
      this.contactInfo = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      }
    }
  }
}
</script>