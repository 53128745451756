import { defineStore } 	from 'pinia'
import axios from 'axios'

export const finalizeQuoteStore = defineStore('finalizeQuoteStore', {
  state: () => ({
    submissionData: null,
		contactInfo: {},
    contactModal: {
      title: 'Add contact',
      show: false,
      callMethod: 'save'
    },
    commodityOptions: [
      { name: 'agricultural_equipment', label: 'Agricultural equipment'},
      { name: 'beverages', label: 'Beverages (non-alcoholic)'},
      { name: 'building_materials', label: 'Building materials'},
      { name: 'bulk_or_bagged_nuts', label: 'Bulk or Bagged Nuts'},
      { name: 'cameras_and_film', label: 'Cameras and film'},
      { name: 'canned_goods', label: 'Canned goods'}
    ],
    selectedCommodities: [],
    selectedCommodityToUpdate: null,
    selectedVehicleToUpdate: null,
    cargoModal: {
      title: 'Add commodity',
      show: false,
      callMethod: 'save'
    },
    truckModal: {
      title: 'Add truck',
      show: false,
      callMethod: 'save'
    },
    trailerModal: {
      title: 'Add trailer',
      show: false,
      callMethod: 'save'
    },
    driverModal: {
      title: 'Add driver',
      show: false,
      callMethod: 'save'
    },
    addressModal: {
      title: 'Garaging address',
      show: false,
      callMethod: 'save',
      type: null
    },
    businessModal: {
      title: 'Edit business info',
      show: false,
      callMethod: 'update'
    }
  }),
  actions: {
    async getQuoteData(id) {
      var res = await axios.get('/api.json', JSON.stringify({id: id}))
      this.submissionData = res.data
      return
    },
    async updateBusinessInfo(businessInformation) {
      this.submissionData.businessInformation = businessInformation
      return
    },
    async updateCommodity(selectedCommodity) {
      this.selectedCommodities[this.selectedCommodityToUpdate].name = selectedCommodity.name
      this.selectedCommodities[this.selectedCommodityToUpdate].label = selectedCommodity.label
      return
    },
    async saveCommodity(selectedCommodity) {
      this.selectedCommodities.push({ name: selectedCommodity.name, label: selectedCommodity.label, percentage: 0})
      return
    },
    async removeCommodity(index) {
      this.selectedCommodities.splice(index, 1)
    },
    async updateContactInfo(contactInfo) {
      this.submissionData.contactInformation = contactInfo
      return
    },
    async saveContactInfo(contactInfo) {
      this.submissionData.contactInformation = contactInfo
      return
    },
    async addOperation(operation) {
      this.submissionData.operations.push(operation)
      return
    },
    async removeOperation(operation) {
      const updatedOperations = this.submissionData.operations.filter(value => {
        return value !== operation
      })
      this.submissionData.operations = updatedOperations
    },
    async saveTruck(truck) {
      this.submissionData.vehicles.push(truck)
      return
    },
    async updateTruck(truck) {
      this.submissionData.vehicles[this.selectedVehicleToUpdate] = truck
      return
    },
    async removeTruck(index) {
      this.submissionData.vehicles.splice(index, 1)
    },
    async saveTrailer(trailer) {
      this.submissionData.trailers.push(trailer)
      return
    },
    async updateTrailer(trailer) {
      this.submissionData.trailers[this.selectedTrailerToUpdate] = trailer
      return
    },
    async removeTrailer(index) {
      this.submissionData.trailers.splice(index, 1)
    },
    async saveDriver(driver) {
      this.submissionData.drivers.push(driver)
      return
    },
    async updateDriver(driver) {
      this.submissionData.drivers[this.selectedDriverToUpdate] = driver
      return
    },
    async removeDriver(index) {
      this.submissionData.drivers.splice(index, 1)
    },
    async updateAddress(address) {
      var addressIndex = this.submissionData.address.findIndex((obj => obj.type == address.type))
      this.submissionData.address[addressIndex] = address
    }
  }
})