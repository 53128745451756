import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/views/QuickQuote/'
import Dot from '@/views/QuickQuote/DOTView'
import DotData from '@/views/QuickQuote/DOTDataView'
import HaulType from '@/views/QuickQuote/HaulTypeView'
import Vin from '@/views/QuickQuote/VINView'
import VinData from '@/views/QuickQuote/VINDataView'
import YearsOnEquipment from '@/views/QuickQuote/YearsOnEquipmentView'
import PolicyholderAge from '@/views/QuickQuote/PolicyholderAgeView'
//import DrivingExperience from '@/views/QuickQuote/DrivingExperienceView'
import GetQuote from '@/views/QuickQuote/GetQuoteView'
import FinalizeQuote from '@/views/FinalizeQuote/'
import {useAuthStore} from "@/stores/authStore";

const routes = [

    {
        path: '/:userId(\\d+)',
        component: Home
    },
    {
        path: '/dot',
        component: Dot
    },
    {
        path: '/dotdata',
        component: DotData
    },
    {
        path: '/haultype',
        component: HaulType
    },
    {
        path: '/vin',
        component: Vin
    },
    {
        path: '/vindata',
        component: VinData
    },
    {
        path: '/yearsonequipment',
        component: YearsOnEquipment
    },
    {
        path: '/policyholderage',
        component: PolicyholderAge
    },
    // {
    //     path: '/drivingexperience',
    //     component: DrivingExperience
    // },
    {
        path: '/getquote',
        component: GetQuote
    },
    {
        path: '/finalize-quote/:id',
        component: FinalizeQuote
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async () => {
    const authStore = useAuthStore();
    if (!authStore.authToken) {
        authStore.clear();
        await authStore.getToken();
    }
});

export default router
