<template>
  <div class="accordion-conponent separator">
    <!-- Tittle -->
    <h3>Trucks</h3>
    <p class="fontbody3">Seconday copy is needed.</p>

    <div class="row">

      <!-- Trucks Options -->
      <div class="col-lg-6 col-xl-4 mt-4" v-for="(truck, index) in store.submissionData.vehicles" :key="index">
        <div class="card truck-card">

          <!-- Card body -->
          <div class="card-body">
            <p class="card-title text-uppercase fontbody5 mb-3">{{ truck.bodyTypeKey }}</p>
            <h4 class="mb-3">{{ truck.year }} {{ truck.make }} {{ truck.model }}</h4>
            <p class="fontbody3">{{ truck.vin }}</p>

            <!-- Overlay-menu-->
            <div @click="this.$refs.truckMenu[0].toggle($event)" class="edit-menu clickable">
              <i class="pi pi-ellipsis-v"></i>
              <Menu ref="truckMenu" class="overlay-menu" :popup="true">
                <template #start>
                  <ul>
                    <li @click="edit(index)">Edit</li>
                    <li @click="remove(index)">Remove</li>
                  </ul>
                </template>
              </Menu>
            </div>
          </div>
          
        </div>
      </div>

       <!-- ADD TRUCK CARD -->
       <div class="col-lg-6 col-xl-4 mt-4">
        <div class="card truck-card clickable" @click="add">
          <div class="card-body">
            <div class="text-center mt-1 color-primary">
              <i class="pi pi-plus-circle" style="font-size: 2rem"></i>
              <h5 class="mt-2">Add Truck</h5>
              <p class="fontbody5">You can add up to 20 trucks.</p>
            </div>    
          </div>
        </div>
      </div>

    </div>
    
    <!-- Modals -->
    <ModalAddTruck />

  </div>
</template>

<script>
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore"
import ModalAddTruck from '@/components/FinalizeQuote/Equipment/ModalAddTruck'
import Menu from 'primevue/menu'

export default {
  components: {
    ModalAddTruck,
    Menu
  },
  data() {
    return {}
  },
  setup() {
    const store = finalizeQuoteStore()
    return { store }
  },
  methods: {
    add() {
      this.store.truckModal.title = 'Add truck'
      this.store.truckModal.callMethod = 'save'
      this.store.truckModal.show = true
    },
    edit(index) {
      this.store.selectedVehicleToUpdate = index
      this.store.truckModal.title = 'Edit truck'
      this.store.truckModal.callMethod = 'update'
      this.store.truckModal.show = true
    },
    remove(index) {
      this.store.removeTruck(index)
    }
  }
}
</script>

<style>
.card.truck-card{
  height: 156px;
}
</style>