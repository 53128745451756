<template>
  <div>
    <Dialog 
      :header="store.businessModal.title" 
      v-model:visible="store.businessModal.show" 
      :style="{width: '480px'}" 
      :modal="true"
    >
      <TabView>
        <TabPanel header="Future policyholder">
          <div>
            <div class="mb-3">
              <label for="businessName" class="form-label-font mb-1">Business name</label>
              <input 
                v-model="businessInformation.businessName" 
                :class="['form-control', { 'is-invalid': v$.businessInformation.businessName.$errors.length }]" 
                type="text" 
                name="businessName" 
                id="businessName"
              />
            </div>
            <div class="mb-3">
              <label for="dotNumber" class="form-label-font mb-1">DOT number</label>
              <input 
                v-model="businessInformation.dotNumber" 
                :class="['form-control', { 'is-invalid': v$.businessInformation.dotNumber.$errors.length }]" 
                type="text" 
                name="dotNumber" 
                id="dotNumber"
              />
            </div>
            <div class="mb-3">
              <label for="entityType" class="form-label-font mb-1">Entity type</label>
              <select v-model="businessInformation.entityType" name="entityType" id="entityType" :class="['form-select', { 'is-invalid': v$.businessInformation.entityType.$errors.length }]">
                <option v-for="(entityType, index) in mainStore.entityTypes" :key="index" :value="entityType">
                  {{ entityType }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="yearsInBusiness" class="form-label-font mb-1">Years in business</label>
              <select v-model="businessInformation.yearsInBusiness" name="yearsInBusiness" id="yearsInBusiness" :class="['form-select', { 'is-invalid': v$.businessInformation.yearsInBusiness.$errors.length }]">
                <option v-for="(year, index) in displayYearsInBusiness()" :key="index" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="yearsInBusinessMonths" class="form-label-font mb-1">Months in business</label>
              <select v-model="businessInformation.yearsInBusinessMonths" name="yearsInBusinessMonths" id="yearsInBusinessMonths" :class="['form-select', { 'is-invalid': v$.businessInformation.yearsInBusinessMonths.$errors.length }]">
                <option v-for="(year, index) in displayYearsInBusinessMonths()" :key="index" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="ownerName" class="form-label-font mb-1">Owner's name</label>
              <input 
                v-model="businessInformation.ownerName" 
                :class="['form-control', { 'is-invalid': v$.businessInformation.ownerName.$errors.length }]" 
                type="text" 
                name="ownerName" 
                id="ownerName"
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Additional insured">
          <div>
            <div class="mb-3">
              <label for="additionalInsuredName" class="form-label-font mb-1">Name</label>
              <input 
                v-model="additionalInsured.name" 
                :class="['form-control', { 'is-invalid': false }]" 
                type="text" 
                name="additionalInsuredName" 
                id="additionalInsuredName"
              />
            </div>
            <div class="mb-3">
              <label for="additionalInsuredAddress" class="form-label-font mb-1">Address</label>
              <input 
                v-model="additionalInsured.address" 
                :class="['form-control', { 'is-invalid': false }]" 
                type="text" 
                name="additionalInsuredAddress" 
                id="additionalInsuredAddress"
              />
            </div>
            <div class="mb-3">
              <label for="additionalInsuredCity" class="form-label-font mb-1">City</label>
              <input 
                v-model="additionalInsured.city" 
                :class="['form-control', { 'is-invalid': false }]" 
                type="text" 
                name="additionalInsuredCity" 
                id="additionalInsuredCity"
              />
            </div>
            <div class="mb-3">
              <label for="additionalInsuredState" class="form-label-font mb-1">State</label>
              <select v-model="additionalInsured.state" name="additionalInsuredState" id="additionalInsuredState" :class="['form-select', { 'is-invalid': false }]" >
                <option value="">Select State</option>
                <option v-for="(state, index) in mainStore.states" :key="index" :value="index">
                  {{ state }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="additionalInsured.zipCode" class="form-label-font mb-1">ZIP Code</label>
              <input 
                v-model="additionalInsured.zipCode" 
                :class="['form-control', { 'is-invalid': false }]" 
                type="text" 
                name="additionalInsuredZipCode" 
                id="additionalInsuredZipCode"
              />
            </div>
          </div>
        </TabPanel>
      </TabView>
      <div>
          <button type="button" class="btn btn-primary w-100" @click="this[store.businessModal['callMethod']]()">Save</button>
        </div>
    </Dialog>
  </div>
</template>
  
<script>
import Dialog from 'primevue/dialog/sfc'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import { mainStore as main } from "@/stores/mainStore"
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore"
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  components: {
    Dialog,
    TabView,
    TabPanel
  },
  data() {
    return {
      businessInformation: {
        businessName: null,
        dotNumber: null,
        entityType: null,
        yearsInBusiness: null,
        yearsInBusinessMonths: null,
        ownerName: null
      },
      additionalInsured: {
        name: null,
        address: null,
        city: null,
        state: null,
        zipCode: null
      }
    }
  },
  setup() {
    const store = finalizeQuoteStore()
    const mainStore = main()
    return { store, mainStore, v$: useVuelidate() }
  },
  validations() {
    return {
      businessInformation: {
        businessName: { required },
        dotNumber: { required },
        entityType: { required },
        yearsInBusiness: { required },
        yearsInBusinessMonths: { required },
        ownerName: { required }
      }
    }
  },
  watch: {
    'store.businessModal.show': function () {
      if (this.store.businessModal.show && this.store.businessModal.callMethod == 'update') {
        this.businessInformation = this.store.submissionData.businessInformation
      }
    },
  },
  methods: {
    displayYearsInBusiness() {
      const years = []
      for (let i = 0; i <= 100; i++) {
        years.push(i)
      }
      return years
    },
    displayYearsInBusinessMonths() {
      const months = []
      for (let i = 0; i <= 12; i++) {
        months.push(i)
      }
      return months
    },
    async update() {
      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      await this.store.updateBusinessInfo(this.businessInformation)
      this.store.businessModal.show = false
    }
  }
}
</script>