<template>
  <div>
    <TopNavbar :nav-link="store.mainNavigationURL"/>

    <div class="container">
      <div class="row">
        <div class="col qq-container">

          <div class="mt-5">
            <h1>Select haul radius</h1>
            <p>
              Choose the average distance cargo is hauled from the garaging location.
            </p>
          </div>

          <div :class="{'checked' : haulType === this.haulTypes.local}" class="form-check radius-input mt-4">
            <input
                type="radio"
                name="haul_type"
                class="standard-radio-input"
                v-model="haulType"
                value="haul_local"
            />
            <label class="form-check-label"> Local (0-49 miles)</label>
          </div>

          <div :class="{'checked' : haulType === this.haulTypes.intermediate}" class="form-check radius-input mt-4">
            <input
                type="radio"
                name="haul_type"
                class="standard-radio-input"
                v-model="haulType"
                value="haul_intermediate"
            />
            <label class="form-check-label"> Intermediate (50-199 miles)</label>
          </div>

          <div :class="{'checked' : haulType === this.haulTypes.long}" class="form-check radius-input mt-4">
            <input
                type="radio"
                name="haul_type"
                class="standard-radio-input"
                v-model="haulType"
                value="haul_long"
            />
            <label class="form-check-label"> Long (200+ miles)</label>
          </div>

          <!-- Back and Next Buttons -->
          <div class="row mt-5">
            <div class="col-6">
              <button class="btn btn-default w-100 mt-5"
                      @click="back">
                Back
              </button>
            </div>
            <div class="col-6">
              <button class="btn btn-primary w-100 mt-5"
                      :disabled="disableNext"
                      @click="next">
                Next
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {quickQuoteStore} from "@/stores/quickQuoteStore";
import TopNavbar from "@/components/TopNavbar.vue";

export default {
  components: {TopNavbar},
  data() {
    return {
      haulType: '',
      haulTypes: {
        local: 'haul_local',
        intermediate: 'haul_intermediate',
        long: 'haul_long'
      }
    }
  },
  mounted() {
    if (this.store.submissionData.haulType !== '') {
      this.haulType = this.store.submissionData.haulType
    }
  },
  setup() {
    const store = quickQuoteStore();
    return {store};
  },
  computed: {
    disableNext() {
      return this.haulType === '' || this.haulType === null || this.haulType === undefined
    }
  },
  watch: {
    haulType(newHaulType) {
      if (newHaulType === this.haulTypes.local) {
        this.store.submissionData.haulType = this.haulTypes.local
        this.store.submissionData.radius_local = "Y"
        this.store.submissionData.radius0_50 = "75"
        this.store.submissionData.radius51_200 = "25"
        this.store.submissionData.radius201_500 = "0"
        this.store.submissionData.radius501 = "0"
        this.store.submissionData.radius_intermediate = "N"
        this.store.submissionData.radius_longhaul = "N"
      }

      if (newHaulType === this.haulTypes.intermediate) {
        this.store.submissionData.haulType = this.haulTypes.intermediate
        this.store.submissionData.radius_intermediate = "Y"
        this.store.submissionData.radius0_50 = "25"
        this.store.submissionData.radius51_200 = "50"
        this.store.submissionData.radius201_500 = "25"
        this.store.submissionData.radius501 = "0"
        this.store.submissionData.radius_local = "N"
        this.store.submissionData.radius_longhaul = "N"
      }

      if (newHaulType === this.haulTypes.long) {
        this.store.submissionData.haulType = this.haulTypes.long
        this.store.submissionData.radius_longhaul = "Y"
        this.store.submissionData.radius0_50 = "0"
        this.store.submissionData.radius51_200 = "0"
        this.store.submissionData.radius201_500 = "50"
        this.store.submissionData.radius501 = "50"
        this.store.submissionData.radius_local = "N"
        this.store.submissionData.radius_intermediate = "N"
      }
    }
  },
  methods: {
    async back() {
      this.$router.push("/dotdata");
    },
    async next() {
      await this.$router.push("vin");
    },
  },
};
</script>

<style>
</style>
