<template>
  <div class="col-sm-12 col-md fq-left-navbar" >
    <div class="fq-left-navbar-body">
      <div class="row">
        <div class="fontbody2 mb-2 col col-md-12">🗂️ Submission</div>
        <div class="fontbody2 mb-2 col col-md-12 submission-id"> #1234567890</div>
      </div>
      <ul>
        <li class="clickable mt-4 fontbody3" @click="scrollToElem('policyholder_section')">
          Policyholder
        </li>
        <li class="clickable mt-4 fontbody3" @click="scrollToElem('operations_section')">
          Operations
        </li>
        <li class="clickable mt-4 fontbody3" @click="scrollToElem('equipment_section')">
          Equipment
        </li>
        <li class="clickable mt-4 fontbody3" @click="scrollToElem('drivers_section')">
          Drivers        
        </li>
        <li class="clickable mt-4 fontbody3" @click="scrollToElem('coverages_section')">
          Coverages
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
methods:{
  scrollToElem(elemID){
    window.scrollTo({
      top: document.getElementById(elemID).offsetTop - 240,
      left: 0,
      behavior: "smooth",
    });
  }
}
}
</script>
<style>
.fq-left-navbar{
  max-width: 150px!important;
}
.fq-left-navbar-body{
  position: fixed;
  width: 150px ;
}
.fq-left-navbar ul {
    padding-left: 0px;
}
.fq-left-navbar ul li{
  text-decoration: none;
  list-style-type:none;
}
@media (max-width: 768px){
.fq-left-navbar{
  position: fixed;
  top: 132px;
  right: 0;
  left: 0;
  z-index: 1030;
  background: white;
  padding: 20px 0;
  border-bottom: 1px solid #E6E1E9;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.fq-left-navbar,
.fq-left-navbar-body
{
  max-width: unset!important;
  width: 100%;
}
.fq-left-navbar-body{
  position: unset;
}
.fq-left-navbar-body .submission-id{
  text-align: right;
}
.fq-left-navbar ul {
    margin-bottom:0 ;
    overflow: auto;
    white-space:nowrap;
    padding: 20px 0;
}
.fq-left-navbar ul li{
  display: inline-block;
  border: solid 1px gray;
  border-radius: 50px ;
  padding:7px 15px;
  margin: 0 10px;
  display: inline;
}


}

</style>