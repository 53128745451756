<template>
	<div class="accordion-conponent separator">
    <!-- Tittle -->
    <h3>Business</h3>
    <p class="fontbody3">Secondary copy is needed</p>

		<div class="row">

      <!-- Business info -->
			<div class="col-lg-6 mt-4">
        <div class="card info-card">
          <div class="edit-menu clickable" @click="editBusinessInfo"><i class="pi pi-pencil"></i></div>
          <div class="card-body">
            <p class="card-title fontbody5 mb-4">BUSINESS</p>
            <h3 class="card-subtitle mb-3">{{ store.submissionData.businessInformation.businessName }}</h3>
            <p class="fontbody2 mb-3">{{ store.submissionData.businessInformation.entityType }}, 5 years in business</p>
            <p class="fontbody2 mb-3">USDOT {{ store.submissionData.businessInformation.dotNumber }}</p>
            <p class="fontbody3 mb-0">1 Additional insured</p>
          </div>
        </div>
			</div>

      <!-- Contact info -->
      <div v-if="Object.keys(store.submissionData.contactInformation).length > 0" class="col-lg-6 mt-4">
        <div class="card info-card">
          <div class="card-body">
            <p class="card-title fontbody5 mb-4">CONTACT</p>
            <h3 class="card-subtitle mb-3">{{ store.submissionData.contactInformation.firstName }} {{ store.submissionData.contactInformation.lastName }}</h3>
            <p class="fontbody2 mb-3">{{ store.submissionData.contactInformation.phone }}</p>
            <p class="fontbody2 mb-3">{{ store.submissionData.contactInformation.email }}</p>
            <p class="fontbody3 mb-0">For all businesss inquiries</p>
          </div>
        </div>
			</div>

      <!-- Add Contact Info -->
      <div v-else class="col-lg-6 mt-4">
        <div class="card info-card clickable" @click="addContact">
          <div class="card-body">
            <div class="text-center mt-1 color-primary">
              <i class="pi pi-plus-circle"></i>
              <h3 class="mt-4">Add contact</h3>
              <p class="fontbody5 mt-3">Who do you reach out to with questions about the policy?</p>
            </div> 
          </div>
        </div>
			</div>

    </div>

    <!-- Modals -->
    <ModalAddContact />
    <BusinessInfoModal />

	</div>
</template>

<script>
import { finalizeQuoteStore } from "@/stores/finalizeQuoteStore"
import ModalAddContact        from '@/components/FinalizeQuote/PolicyHolder/ModalAddContact'
import BusinessInfoModal      from '@/components/FinalizeQuote/PolicyHolder/BusinessInfoModal'

export default {
  components: {
    ModalAddContact,
    BusinessInfoModal
  },
  setup() {
    const store = finalizeQuoteStore()
    return { store }
  },
  methods: {
    // Add contact modal
    addContact() {
      this.store.contactModal.show = true
    },

    // Edit business info
    editBusinessInfo() {
      this.store.businessModal.show = true
    },

    // Edit contact modal
    async editContact() {
      this.store.contactModal.title = 'Edit contact'
      this.store.contactModal.show = true
      this.store.contactModal.callMethod = 'update'
    }
  }
}
</script>

<style>
.card.info-card{
  height: 220px;
}
.card.info-card i.pi-plus-circle{
  font-size: 60px
}
</style>