<template>
  <div>
    <TopNavbar :nav-link="store.mainNavigationURL"/>

    <div class="container">
      <div class="row">
        <div class="col qq-container">

          <div class="mt-5">
            <h1>Enter driver’s DOB</h1>
          </div>


          <div class="form-floating mt-4">
            <input
                type="date"
                :class="['form-control', { 'is-invalid': v$.dobVal.$errors.length }]"
                placeholder=""
                v-model="dobVal"
            />
            <label>Date of birth</label>
            <div v-show="v$.dobVal.$errors.length" class="feedback invalid-feedback">
              Driver must be between 23 and 70 years of age.
            </div>
          </div>

          <!-- Back and Next Buttons -->
          <div class="row mt-5">
            <div class="col-6">
              <button class="btn btn-default w-100 mt-5"
                      @click="back">
                Back
              </button>
            </div>
            <div class="col-6">
              <button class="btn btn-primary w-100 mt-5"
                      @click="next">
                Submit
              </button>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {quickQuoteStore} from "@/stores/quickQuoteStore";
import {useVuelidate} from '@vuelidate/core'
import {required, minLength, maxLength} from '@vuelidate/validators'
import moment from 'moment';
import TopNavbar from "@/components/TopNavbar.vue";

export default {
  components: {TopNavbar},
  data() {
    return {
      dobVal: '1980-01-01',
    }
  },
  setup() {
    const store = quickQuoteStore();
    return {store, v$: useVuelidate()};
  },
  validations() {
    return {
      dobVal: {required, minLength: minLength(10), maxLength: maxLength(10)},
    }
  },
  methods: {
    async checkDob() {
      //  Save values into Validations Checkers
      this.store.submissionData.policyHolderDOB = this.dobVal

      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      //  Convert DOB to MM/DD/YYYY
      this.store.submissionData.policyHolderDOB = moment(this.store.submissionData.policyHolderDOB).format("MM/DD/YYYY")

      // Go to next view
      this.$router.push('getquote')
    },
    async back() {
      this.$router.push('yearsonequipment')
    },
    async next() {
      await this.checkDob()
    },
  }

}
</script>
