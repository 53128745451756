import { defineStore } 	from 'pinia'
import axios from 'axios'

export const mainStore = defineStore('mainStore', {
  state: () => ({
    states: null,
    entityTypes: [
      'Individual',
      'Corporation',
      'Limited Liability Company (LLC)',
      'Joint Venture',
      'Partnership'
    ]
  }),
  actions: {
    async getStates() {
      var res = await axios.get('/states.json')
      this.states = res.data
      return
    }
  }
})