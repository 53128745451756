<template>
  <div>
    <TopNavbar :nav-link="store.mainNavigationURL"/>

    <div class="container">
      <div class="row">
        <div class="col qq-container">

          <!-- if found -->
          <div v-if="store.submissionData.dot" class="mt-5">
            <h1 class="color-neutral">Confirm business details</h1>
            <p>
              Verify information or edit as necessary.
            </p>
          </div>

          <!-- If manual data or not found -->
          <div v-else class="mt-5">
            <h1 class="color-neutral">Enter business details</h1>
            <p>
              Provide a business name and garaging location.
            </p>
          </div>

          <!-- dynamic inputs -->
          <div>
            <!-- Business name -->
            <div class="form-floating mt-4">
              <input
                  type="text"
                  :class="['form-control', { 'is-invalid': v$.legalNameVal.$errors.length }]"
                  placeholder="Business Name"
                  v-model="store.submissionData.legalName"
              />
              <label>Business Name</label>
              <div v-show="v$.legalNameVal.$errors.length" class="feedback invalid-feedback">
                A valid Business Name is required.
              </div>
            </div>

            <!-- Address -->
            <div class="form-floating mt-4">
              <input
                  type="text"
                  :class="['form-control', { 'is-invalid': v$.phyAddressStreet.$errors.length }]"
                  placeholder="Address"
                  v-model="store.submissionData.phyAddressStreet"
              />
              <label>Address</label>
              <div v-show="v$.phyAddressStreet.$errors.length" class="feedback invalid-feedback">
                A valid Address is required.
              </div>
            </div>

            <!-- City -->
            <div class="form-floating mt-4">
              <input
                  type="text"
                  :class="['form-control', { 'is-invalid': v$.phyAddressCityVal.$errors.length }]"
                  placeholder="City"
                  v-model="store.submissionData.phyAddressCity"
              />
              <label>City</label>
              <div v-show="v$.phyAddressCityVal.$errors.length" class="feedback invalid-feedback">
                A valid City is required.
              </div>
            </div>

            <!-- State -->
            <div class="form-floating mt-4">
              <select
                  :class="['form-select ', { 'is-invalid': v$.phyAddressStateVal.$errors.length }]"
                  v-model="store.submissionData.phyAddressState"
              >
                <option value="">Select State</option>
                <option v-for="(state, short) in statesUS" v-bind:key="short" :value="short">
                  {{ state }}
                </option>

              </select>
              <label>State</label>
              <div v-show="v$.phyAddressStateVal.$errors.length" class="feedback invalid-feedback">
                A valid State is required.
              </div>
            </div>

            <!-- Zip -->
            <div class="form-floating mt-4">
              <input
                  type="text"
                  :class="['form-control', { 'is-invalid': v$.phyAddressZipVal.$errors.length }]"
                  placeholder="Zip"
                  v-model="store.submissionData.phyAddressZip"
              />
              <label>Zip</label>
              <div v-show="v$.phyAddressZipVal.$errors.length" class="feedback invalid-feedback">
                A valid 5 digit numeric Zip Code is required
              </div>
            </div>
          </div>

          <!-- Back and Next Buttons -->
          <div class="row mt-5">
            <div class="col-6">
              <button class="btn btn-default w-100 mt-5"
                      @click="back">
                Back
              </button>
            </div>
            <div class="col-6">
              <button class="btn btn-primary w-100 mt-5"
                      :disabled="disableNext"
                      @click="next">
                Next
              </button>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {quickQuoteStore} from "@/stores/quickQuoteStore"
import statesJson from '@/assets/json/states'
import {useVuelidate} from '@vuelidate/core'
import {required, minLength, maxLength, numeric} from '@vuelidate/validators'
import moment from 'moment';
import TopNavbar from "@/components/TopNavbar.vue";


export default {
  components: {TopNavbar},
  data() {
    return {
      statesUS: statesJson,
      legalNameVal: '',
      phyAddressStreet: '',
      phyAddressCityVal: '',
      phyAddressStateVal: '',
      phyAddressZipVal: '',
      todayDate: moment(new Date()).format("MM/DD/YYYY")
    }
  },
  setup() {
    const store = quickQuoteStore();
    return {store, v$: useVuelidate()};
  },
  validations() {
    return {
      legalNameVal: {required, minLength: minLength(3)},
      phyAddressStreet: {required},
      phyAddressCityVal: {required},
      phyAddressStateVal: {required},
      phyAddressZipVal: {required, numeric, minLength: minLength(5), maxLength: maxLength(5)}
    }
  },
  computed: {
    disableNext() {
      if (!this.store.submissionData.legalName) {
        return true
      }
      if (!this.store.submissionData.phyAddressStreet) {
        return true
      }
      if (!this.store.submissionData.phyAddressCity) {
        return true
      }
      if (!this.store.submissionData.phyAddressState) {
        return true
      }
      if (!this.store.submissionData.phyAddressZip) {
        return true
      }
      return false
    }
  },
  methods: {
    async checkDotData() {
      //  Save values into Validations Checkers
      this.legalNameVal = this.store.submissionData.legalName
      this.phyAddressStreet = this.store.submissionData.phyAddressStreet
      this.phyAddressCityVal = this.store.submissionData.phyAddressCity
      this.phyAddressStateVal = this.store.submissionData.phyAddressState
      this.phyAddressZipVal = this.store.submissionData.phyAddressZip

      //  Check Requirements and Validations
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      this.store.submissionData.legalName = this.legalNameVal
      this.store.submissionData.mailAddressStreet = this.phyAddressStreet
      this.store.submissionData.mailAddressCity = this.phyAddressCityVal
      this.store.submissionData.mailAddressState = this.phyAddressStateVal
      this.store.submissionData.mailAddressZip = this.phyAddressZipVal
      this.store.submissionData.mailAddressCountry = false
      this.store.submissionData.mailingCounty = 'A'

      // Go to next view
      this.$router.push('haultype')
    },
    async back() {
      this.$router.push("/dot");
    },
    async next() {
      await this.checkDotData()
    }
  }
};
</script>
