<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top main-nav">
      <div class="container-fluid">
        <a class="navbar-brand main-logo" :href="navLink"><img :src="logo" alt="coverwhale logo"></a>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
          <!--          <ul class="navbar-nav ">-->
          <!--            <li class="nav-item">-->
          <!--              <a class="nav-link" href="#">link</a>-->
          <!--            </li>-->
          <!--          </ul>-->
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  </div>
</template>

<script>
import cwlogo from '@/assets/img/cwis_logo.png'

export default {
  props: {
    navLink: String
  },
  data() {
    return {
      logo: cwlogo,

    }
  }
}
</script>


<style>
nav.navbar {
  height: 70px;
  background: var(--colorNeutral00);
  border-bottom: 1px solid #E6E1E9;
  color: white;
}
</style>
